<template>
    <div class="contact">
      <div class="topbox">
          <span>先锋云信，探索更多成功的可能！</span>
          <router-link class="contacttitle" to="/contact">联系我们</router-link>
      </div>
      <div class="bottombox">
          <div class="menu">
              <div class="example">
                  <span class="title">产品</span>
                  <div class="item">
                      <router-link class="href" to="/aboutXf">先锋云信</router-link>
                      <router-link class="href" to="/aboutLiveMeeting">视频会议</router-link>
                      <router-link class="href" to="/aboutQuantum">量子加密</router-link>
                  </div>
              </div>
              <div class="example">
                  <span class="title">价格</span>
              </div>
              <div class="example">
                  <span class="title">解决方案</span>
                  <div class="item">
                      <router-link class="href" to="/solution">行业方案</router-link>
                      <router-link class="href" to="/solution">典型案例</router-link>
                  </div>
              </div>
              <div class="example">
                  <span class="title">下载中心</span>
              </div>
              <div class="example">
                  <span class="title">联系我们</span>
              </div>
              <div class="contactus">
                  <span class="concern">关注或联系我们</span>
                  <span class="phone">联系人电话： 139-xxxx-xxxx</span>
                  <img class="code" src="/assets/code.png" alt="">
              </div>
          </div>
          <div class="filings">
              ©2024 成都同村科技有限公司
              <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2024056584号</a>
          </div>
      </div>
    </div>
  </template>
  
  <script lang='ts'>
  import { defineComponent } from "vue";
  
  export default defineComponent({
  
  })
  </script>
  
  <style lang="scss" scoped>
  
  .contact {
      width: 100%;
      display: flex;
      flex-direction: column;
  
      .topbox {
        height: 26%;
        display: flex;
        color: white;
        box-sizing: border-box;
        font-size: 1.75rem;
        font-weight: 900;
        padding: 40px 0px 40px 60px;
        align-items: center;
        justify-content: space-between;
        background: url('/public/assets/contactbg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
  
        .contacttitle {
            font-size: 1.25rem;
            margin-right: 200px;
            background: #479BED;
            padding: 15px 40px;
            border-radius: 20px;
            cursor: pointer;
            text-decoration: none;
            color: white;
        }
      }
  
      .bottombox {
          padding: 60px 60px 60px 120px;
          display: flex;
          flex-direction: column;
  
          .menu {
              display: flex;
              flex-direction: row;
  
              .example {
                  display: flex;
                  flex-direction: column;
                  margin-right: 100px;
                  align-items: flex-start;
  
                  .item {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
  
                      .href {
                          text-decoration: none;
                          margin-top: 10px;
                          color: #616161;
                          font-size: 1rem;
  
                          &:hover {
                              color: #009DFF;
                          }
                      }
                  }
              }
  
              .title {
                  font-size: 1.5rem;
                  color: #333333;
                  font-weight: 600;
              }
  
              .contactus {
                  display: none;
                //   display: flex;
                  flex-direction: column;
                  margin-left: 80px;
                  align-items: flex-start;
  
                  .concern {
                      font-size: 20px;
                      color: #0D0D0D
                  }
  
                  .phone {
                      font-size: 16px;
                      margin-top: 10px;
                      color: #0D0D0D
                  }
  
                  .code {
                      width: 100px;
                      height: 100px;
                      margin-left: -12px;
                  }
              }
          }
  
          .filings {
              text-align: center;
              font-size: 1rem;
              color: #666666;
              margin-top: 100px;

              a {
                text-decoration: none;
                color: #666666;
              }
          }
      }
  }
  </style>