import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/aboutXF',
    name: 'about',
    component: () => import(/* webpackChunkName: "aboutXF" */ '../views/AboutXF.vue')
  }, 
  {
    path: '/aboutLiveMeeting',
    name: 'aboutLiveMeeting',
    component: () => import(/* webpackChunkName: "aboutLive" */ '../views/AboutLiveMeeting.vue')
  },
  {
    path: '/aboutQuantum',
    name: 'aboutQuantum',
    component: () => import(/* webpackChunkName: "aboutquantum" */ '../views/AboutQuantum.vue')
  },
  {
    path:'/contact',
    name:'contact',
    component: () =>import('../views/Contact.vue')
  },
  {
    path:'/download',
    name:'download',
    component: () =>import('../views/Download.vue')
  },
  {
    path:'/solution',
    name:'solution',
    component: () =>import('../views/Solution.vue')
  },
  {
    path:'/price',
    name:'price',
    component: () =>import('../views/Price.vue')
  },
  {
    path:'/try',
    name:'try',
    component: () =>import('../views/Try.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path:'/serviceAgreement',
    name:'serviceAgreement',
    component: () =>import('../views/ServiceAgreement.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
