<template>
  <TitleBar/>
  <router-view v-slot="{ Component }">
    <keep-alive include="tryout">
      <component :is="Component"/>
    </keep-alive>
  </router-view>

  <div class="contact-bar">
    <div class="desc" @click="onContact">
      <div class="img-wrapper">
        <img src="/assets/contact.png">
      </div>
      <span>
        联系我们
      </span>
    </div>
    <div class="tooltip">
      <div class="content">191-8202-4891</div>
    </div>

    <div class="desc phone-number">
      <div class="img-wrapper">
        <img src="/assets/phone.svg">
      </div>
      <span>
        电话联系
      </span>
    </div>
    <div class="tooltip">
      <div class="content">191-8202-4891</div>
    </div>
    <div class="desc QR-code">
      <div class="img-wrapper">
        <img src="/assets/QRCode.svg">
      </div>
      <span>
        微信咨询
      </span>
    </div>
    <div class="tooltip-c">
      <div class="content"><img src="/assets/code.png" alt="" srcset=""></div>
    </div>
  </div>
  <Bottom/>
  <div>
    <el-dialog v-model="dialogContactVisible" title="在线联系" :lock-scroll="false" >
    <el-form :model="contactFrom" class="fromContact" :rules="contactRules" ref="formIns">
      <el-form-item label="留言标题:"  prop="commentTitle" >
        <el-input placeholder="留言标题" v-model="contactFrom.commentTitle" autocomplete="off" maxlength="64" />
      </el-form-item>
      <el-form-item label="留言内容:" prop="commentInfo" >
        <el-input placeholder="留言内容" v-model="contactFrom.commentInfo" autocomplete="off" type="textarea" :autosize="{ minRows: 6, maxRows: 12 }" maxlength="512" />
      </el-form-item>
      <el-form-item label="联系方式:" prop="contactPhone" >
        <el-input placeholder="联系方式" v-model="contactFrom.contactPhone" autocomplete="off" maxlength="64"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogContactVisible = false">取消</el-button>
        <el-button type="primary" @click="openSmsCode">确认</el-button>
      </div>
    </template>
  </el-dialog>
  </div>
  <div>
    <el-dialog v-model="smsCodeVisivle" title="请验证您的身份" width="500" :lock-scroll="false" >
      <div>
        <slide-verify
          class="silde_box"
          ref="sliderVertifyIns"
          slider-text="向右滑动->"
          accuracy="1"
          w="450"
          :imgs="vertifyImgs"
          @again="onAgain"
          @success="onSuccess"
          @fail="onFail"
          @refresh="onRefresh"
        ></slide-verify>
      </div> 
    </el-dialog>
  </div>

</template>
<script lang="ts" setup>
import TitleBar from '@/components/TitleBar.vue';
import Bottom from '@/components/Bottom.vue';
import { ref, reactive } from 'vue';
import { createComments } from './api/api';
import { ElMessage, ElMessageBox, FormInstance, FormRules, MessageParamsWithType } from 'element-plus';
import SlideVerify, { SlideVerifyInstance } from "vue3-slide-verify";
import "vue3-slide-verify/dist/style.css";

let img1 = require('../public/assets/quantumBg.jpg')
let img2 = require('../public/assets/bank.png') 

class cantactApply {
  commentTitle: string | undefined;
  commentInfo:  string | undefined;
  contactPhone: string | undefined;
}

let smsCodeVisivle       = ref<boolean>(false);
let dialogContactVisible = ref<boolean>(false);
let contactFrom          = ref<any>(new cantactApply);
let vertifyImgs          = [img1,img2]
let formIns              = ref<FormInstance>();
let sliderVertifyIns     = ref<SlideVerifyInstance>();

const contactRules = reactive<FormRules<cantactApply>>({
  commentTitle: [
  {
      required: true,
      message: '请输入留言标题',
      trigger: 'blur',
  },],
  commentInfo: [
  {
    required: true,
    message: '请输入留言内容',
    trigger: 'blur',
  },],
  contactPhone: [
  {
    required: true,
    message: '请输入您的联系方式',
    trigger: 'blur',
  },]
})

function putContact(){
  createComments(contactFrom.value).then((res) => {
    if(res.data.code != 0){
      ElMessage.error(res.data.msg)
      dialogContactVisible.value = false
    } else {
      dialogContactVisible.value     = false;
      contactFrom.value.commentTitle = null;
      contactFrom.value.commentInfo  = null;
      contactFrom.value.contactPhone = null;

      ElMessageBox.alert('提交成功，我们将尽快处理您的请求，并通过短信或者电话的方式联系您。', '提交成功', {
        confirmButtonText: '确认'
      })
    }
  }).catch((error: { message: MessageParamsWithType; }) => {
    ElMessage.error(error.message);
  })
}

function openSmsCode() {
  if(contactFrom.value.contactPhone == null){
    ElMessage.error("联系方式不能为空,请重新填写联系方式");
    return;
  }
  if(contactFrom.value.commentTitle == null){
    ElMessage.error("留言标题不能为空,请再试一次");
    return;
  }
  if(contactFrom.value.commentInfo == null){
    ElMessage.error("留言内容不能为空,请再试一次");
    return;
  }

  formIns.value?.validate((valid) => {
    if(valid) {
      smsCodeVisivle.value = true;
    }
  })
}

function onContact() {
  dialogContactVisible.value = true;
}

function onAgain () {
  sliderVertifyIns.value?.refresh();
};

function onSuccess(times: number) {
  ElMessage.success("验证成功");
  smsCodeVisivle.value = false;
  handleClick()
  putContact()
};

function onFail () {
  ElMessage.error("验证失败请再试一次");
};

function onRefresh() {
  console.log('点击了刷新小图标')
};

// 刷新
function handleClick () {
  sliderVertifyIns.value?.refresh();
};

</script>
<style lang="scss">
@import url('./style.scss');

body, html {
  margin: 0px;
  min-height: 768px;
}

#app {
  font-family: 'PingFang SC', 'Microsoft YaHei', 'Microsoft YaHei UI', 'SimSun', 'SimHei', 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  min-width: 1024px;
  min-height: 768px;
  overflow: auto;
}

.el-dialog__title {
  font-weight: bold;
}

.el-tabs__nav-prev {
  display: none;
}

.el-tabs__nav-next {
  display: none;
}

.poper {
  width: 100px;

  .el-menu-item {
    font-size: 1.12rem;
  }
}

.el-menu--popup {
  min-width: 100px !important;
}

.contact-bar {
  // display: none;
  position: fixed;
  right: 0;
  bottom: 31vh;
  height: 168px;
  color: white;
  flex-direction: column;
  justify-content: space-around;
  z-index: 999;

  .desc {
    width: 65px;
    height: 80px;
    background-color: #4fc0ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      &:last-child {
        font-size: 0.75rem;
      }
    }
  }

  .phone-number {
    &:hover {
      cursor: pointer;

      & + .tooltip {
        display: block;
      }
    }
  }

  .tooltip {
    display: none;
    width: 170px;
    padding: 3px 8px;
    position: absolute;
    left: -210px;
    top: 100px;
    font-size: 20px;
    font-weight: 700;
    background-color: black;
    border-radius: 4px;

    .content {
      position: relative;

      &::after {
        position: absolute;
        content: " ";
        right: -11px;
        top: 6px;
        transform: rotateZ(45deg);
        width: 15px;
        height: 15px;
        border-radius: 2px;
        background-color: black;
      }
    }
  }

  .QR-code {
    &:hover {
      & + .tooltip-c {
        display: block;
      }
    }
  }

  .tooltip-c {
    display: none;
    position: absolute;
    width: 110px;
    top: auto;
    left: -135px;
    bottom: -90px;
    padding: 3px 8px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 8px 40px 0 rgb(21 25 42 / 18%);

    div {
      &:last-child {
        img {
          width: 100%;
          height: 100%;   
        }
      }
    }
  }
}
</style>
